main.about {
    width: 100vw;
    box-sizing: border-box;
    padding: 80px var(--margin);
    background-color: white;
    color: var(--blue);
    p {
        margin-top: 40px;
        color: var(--dark-blue);
        width: 900px;
    }
}
@media screen and (max-width: 1520px) {
    main.about {
        p {
            width: calc(75vw - 2 * var(--margin));
        }
    }
}
@media screen and (max-width: 980px) {
    main.about {
        p {
            width: unset;
        }
    }
}
