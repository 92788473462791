body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--low-alpha-blue) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
:root {
  --margin: 120px;
  --blue: #2176ff;
  --blue: #2d77ee;
  --low-alpha-blue: #d7e6ff;
  --high-alpha-blue: #498fff;
  --dark-blue: #01295f;
  --low-alpha-dark-blue: #012a5f9c;
  --nunito: "Nunito Sans", sans-serif;
  --montserrat: "Montserrat", sans-serif;
}

@media screen and (max-width: 1280px) {
  :root {
    --margin: 80px;
  }
}
@media screen and (max-width: 660px) {
  :root {
    --margin: 24px;
  }
}
.feedback-provider-box {
  z-index: 1000000;
}
.hf {
  font-family: var(--nunito);
  font-size: 18px;
  font-weight: bold;
  // color: var(--blue);
  width: max-content;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .hf {
    font-size: 16px;
  }
}

.sp {
  font-family: var(--nunito);
  font-weight: 400;
  font-size: 14px;
  line-height: 141%;
}

.btn {
  font-family: var(--nunito);
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  &.small {
    font-size: 14px;
    line-height: 100%;
  }
}
.little-btn {
  font-family: var(--nunito);
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
}

.title {
  font-family: var(--nunito);
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  text-transform: uppercase;
}
.big-title {
  font-family: var(--nunito);
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  text-transform: uppercase;
}
.subtitle {
  font-family: var(--nunito);
  font-weight: 800;
  font-size: 24px;
  line-height: 33px;
}
.big-subtitle {
  font-family: var(--nunito);
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
}
.pgh {
  font-family: var(--nunito);
  font-weight: 400;
  font-size: 20px;
  line-height: 141%;
}
.pgh-subtitle {
  font-family: var(--nunito);
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}
.sur-pgh {
  font-family: var(--nunito);
  font-weight: 700;
  font-size: 20px;
  line-height: 141%;
}
button.light-button {
  padding: 10px 48px;
  background-color: white;
  color: var(--blue);
  border-radius: 8px;
  outline: none;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: var(--low-alpha-blue);
  }
  &:active {
    color: white;
    background-color: var(--blue);
  }
}
button.dark-button {
  border-radius: 8px;
  padding: 10px 48px;
  color: white;
  outline: none;
  border: none;
  background-color: var(--blue);
  cursor: pointer;
  &:hover {
    background-color: var(--high-alpha-blue);
  }
  &:active {
    background-color: white;
    color: var(--blue);
  }
  &.small {
    min-width: 150px;
    padding: 9px 20;
    text-align: center;
  }
}

html,
.noscrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
html::-webkit-scrollbar,
.noscrollbar::-webkit-scrollbar {
  display: none;
}
