main.login {
    width: 100vw;
    min-height: calc(100vh - 164px);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    > form {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 164px;
        width: 400px;
        h1 {
            margin: 0 0 32px;
            color: var(--dark-blue);
        }
        .login-input {
            border-color: var(--dark-blue);
            margin-bottom: 12px;
            input {
                color: var(--dark-blue);
            }
        }
        > .send-input.login-send {
            margin-top: 24px; /*+12px*/
            background-color: var(--dark-blue);
        }
    }
}
