.switch {
    display: flex;
    height: 24px;
    aspect-ratio: 2;
    display: flex;
    position: relative;
    border-radius: 24px;
    cursor: pointer;
    > div {
        border-radius: 50%;
        height: calc(100% - 2px);
        top: 1px;
        position: absolute;
        aspect-ratio: 1;
        background-color: white;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
    }
    &.on {
        background-color: var(--dark-blue);
        box-shadow: 0px 0px 24px rgba(45, 119, 238, 0.4);
        > div {
            right: 0;
        }
    }
    &.off {
        background-color: white;
        box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.2);
        > div {
            left: 0;
        }
    }
}
