.dispo-popup {
    padding: 32px 40px;
    background-color: white;
    box-shadow: 0px 0px 16px rgba(196, 196, 196, 0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 380px;
    box-sizing: border-box;
    .dispo-head {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 40px;
        h6 {
            margin: 0;
            color: var(--dark-blue);
        }
        img {
            opacity: 0.4;
            cursor: pointer;
            margin-left: 16px;
            height: 20px;
            width: 20px;
            &:hover {
                opacity: 1;
            }
        }
    }
    .dispo-main {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: auto 1fr;
        grid-auto-rows: 1fr;
        margin-bottom: 40px;
        width: 100%;
        color: var(--dark-blue);
        gap: 20px 12px;
        > span {
            margin-bottom: 4px;
            text-align: center;
        }
        .dispo-span {
            color: var(--blue);
            &.checked {
                color: white;
            }
        }
    }
    > button {
        align-self: flex-end;
    }
}

.text-checkbox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 14px;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: var(--low-alpha-blue);
    cursor: pointer;
    > input:first-child {
        position: absolute;
        top: 0;
        height: 0;
        width: 0;
        opacity: 0;
    }
    &.on {
        background-color: var(--blue);
    }
}

@media screen and (max-width: 780px) {
    .dispo-popup {
        .dispo-main {
            grid-template-columns: 1fr;
            grid-auto-columns: 1fr;
            grid-template-rows: repeat(7, auto 1fr);
            row-gap: 6px;
            margin-bottom: 24px;
            .day {
                grid-column: 1/4;
                text-align: left;
                margin-bottom: 0;
                &:nth-child(1) {
                    grid-row: 1/2;
                }
                &:nth-child(2) {
                    grid-row: 3/4;
                }
                &:nth-child(3) {
                    grid-row: 5/6;
                }
                &:nth-child(4) {
                    grid-row: 7/8;
                }
                &:nth-child(5) {
                    grid-row: 9/10;
                }
                &:nth-child(6) {
                    grid-row: 11/12;
                }
                &:nth-child(7) {
                    grid-row: 13/14;
                }
            }
            .text-checkbox {
                margin-bottom: 10px;
                &:nth-child(n + 6) {
                    grid-column: 1/2;
                }
                &:nth-child(n + 15) {
                    grid-column: 2/3;
                }
                &:nth-child(n + 22) {
                    grid-column: 3/4;
                }
                &:nth-child(7n + 1) {
                    grid-row: 2/3;
                }
                &:nth-child(7n + 2) {
                    grid-row: 4/5;
                }
                &:nth-child(7n + 3) {
                    grid-row: 6/7;
                }
                &:nth-child(7n + 4) {
                    grid-row: 8/9;
                }
                &:nth-child(7n + 5) {
                    grid-row: 10/11;
                }
                &:nth-child(7n + 6) {
                    grid-row: 12/13;
                }
                &:nth-child(7n + 7) {
                    grid-row: 14/15;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .dispo-popup {
        padding: 64px var(--margin);
        width: 100vw;
        height: 100vh;
        overflow: auto;
        box-sizing: border-box;
        min-width: unset;

        .dispo-main {
        }
    }
}
@media screen and (max-width: 780px) and (max-height: 730px) {
    .dispo-popup {
        padding: 64px var(--margin);
        width: 100vw;
        height: 100vh;
        overflow: auto;
        box-sizing: border-box;
        min-width: unset;

        .dispo-main {
        }
    }
}

@media screen and (max-width: 350px) {
    .dispo-popup {
        min-width: unset;
        .dispo-main {
            .text-checkbox {
                padding: 8px;
            }
        }
    }
}
