.step_block {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
    color: white;
    h6 {
        font-family: var(--montserrat);
        font-style: normal;
        font-weight: 900;
        font-size: 114px;
        line-height: 80px;
        text-transform: uppercase;
        margin: 0 0 40px;
    }
    h4 {
        margin: 0 0 18px;
    }
    .line {
        height: 4px;
        background-color: white;
        border-radius: 2px;
        margin: 0 0 24px;
    }
}

@media screen and (max-width: 1440px) {
    .step_block {
        h4 {
            height: 66px;
        }
    }
}