header.admin {
    height: 164px;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 30px;
    box-sizing: border-box;
    user-select: none;
    .border {
        width: 100vw;
        height: 32px;
        background-color: var(--dark-blue);
    }
    img {
        height: 60px;
        width: auto;
        margin-left: var(--margin);
    }
}
