.contact-popup {
    background-color: white;
    border-radius: 8px;
    padding: 64px;
    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;
    h2 {
        margin: 0 0 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;
    }
    .mobile {
        display: none;
    }
    form {
        display: grid;
        grid-template-columns: 216px 216px 216px 216px;
        grid-template-rows: repeat(5, 56px);
        grid-auto-rows: 56px;
        column-gap: 20px;
        row-gap: 24px;
        margin-bottom: 64px;
        .input {
            border-color: var(--dark-blue);
            input,
            textarea {
                color: var(--dark-blue);
                &::placeholder,
                &::-moz-placeholder,
                &::-moz-placeholder {
                    color: var(--low-alpha-dark-blue);
                    opacity: 1;
                }
            }
            &.select-input,
            &.button-input .content {
                color: var(--dark-blue);
                &.open > .options {
                    border-color: var(--dark-blue);
                    left: -1px;
                    right: -2px;
                    > .option {
                        color: var(--dark-blue);
                    }
                }
            }
            &.send-input {
                background-color: var(--dark-blue);
            }
            &.size0 {
                grid-column: span 1;
            }
            &.size1,
            &.size2 {
                grid-column: span 2;
            }
            &.size2 {
                grid-row: span 2;
            }
        }
    }
}

.section-deroulante {
    background-color: white;
    max-width: 100vw;
    .head {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--dark-blue);
        img {
            transition-duration: 0.4s;
            transition-property: transform;
            height: 32px;
            width: 32px;
        }
    }
    .body {
        height: 0px;
        overflow: hidden;
        transition-property: height;
        transition-duration: 0.4s;
        .content {
            height: fit-content;
            overflow: hidden;
            margin: 0;
        }
    }
}

@media screen and (max-width: 450px) {
    .contact-popup {
        padding: 80px var(--margin) 40px;
        height: 100vh;
        width: 100vw;
        max-height: unset;
        max-width: unset;
        form{
            display: none;
        }
        div.mobile {
            display: block;
            margin-bottom: 32px;
            h6 {
                margin: 20px 0 8px;
                color: var(--dark-blue);
            }
            span {
                word-break: break-all;
                color: var(--blue);
            }
        }
        img.mobile {
            display: block;
            position: fixed;
            top: 20px;
            right: 20px;
            width: 40px;
            height: 40px;
            z-index: 100000;
        }
    }
}