main.feedback {
    width: 100vw;
    box-sizing: border-box;
    padding: 0 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 164px - 80px);
    background-color: white;
    .blocks {
        align-items: flex-start;
        .content {
            max-width: 490px;
            color: var(--blue);
            margin-bottom: 120px;
            h2 {
                margin: 0 0 16px;
            }
        }
        .feedback-form {
            display: flex;
            flex-direction: column;
            > * {
                margin-bottom: 16px;
                &:last-child {
                    margin-top: 16px;
                }
            }
            .stars {
                margin: 0 0 32px;
            }
        }
    }
}
@media screen and (max-width: 450px) {
    main.feedback .blocks .content {
        margin-bottom: 40px;
    }
}