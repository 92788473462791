@keyframes slide-up {
    0% {
        transform: translateY(16px);
        opacity: 0.3;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes load {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
section.hero {
    background-color: white;
    height: max(556px, calc(100vh - 164px - 56px));
    display: grid;
    grid-auto-columns: 100vw;
    grid-template-rows: 1fr;
    grid-auto-flow: column;
    overflow: hidden;
    position: relative;
    .controls {
        position: absolute;
        z-index: 2;
        &.buttons {
            left: var(--margin);
            bottom: 40px;
            img {
                height: 40px;
                width: 40px;
                cursor: pointer;
                &:first-child {
                    transform: rotate(180deg);
                    margin-right: 80px;
                }
            }
        }
        &.time {
            left: 0;
            bottom: 0;
            height: 6px;
            background-color: var(--low-alpha-blue);
            animation: load var(--transitionDelay) linear infinite;
        }
    }
    .hero-page.first,
    .hero-page.second,
    .hero-page.third,
    .hero-page.fourth {
        h1.desktop {
            display: block;
        }
        h1.mobile {
            display: none;
        }
        &.current .overlay * {
            transform: translateY(16px);
            opacity: 0;
            animation: slide-up var(--transitionDuration) var(--transitionDuration) ease-out forwards;
        }
        h1 {
            margin: 0;
            color: var(--blue);
            margin: 0 0 24px;
            filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 1));
        }
        p {
            color: var(--blue);
            max-width: 450px;
            margin: 0 0 32px;
            text-shadow: 0px 0px 2px #fff;
            filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 1));
        }
    }
    .hero-page.third {
        p {
            max-width: 500px;
        }
    }
}
@media screen and (max-width: 400px) {
    section.hero {
        height: 500px;
        .controls {
            display: none;
        }
    }
}
@media screen and (max-width: 340px) {
    section.hero {
        height: 500px;
        .overlay button {
            display: none;
        }
    }
}