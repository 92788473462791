main.home {
    width: 100vw;
    box-sizing: border-box;
    .sect_01 {
        .photo {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 600px;
            height: 80vh;
        }
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: center;
            border-radius: 8px;
            // height: auto;
            // width: auto;
        }
        h2 {
            color: var(--blue);
            margin: 0 0 32px;
        }
        p {
            padding-right: 80px;
            box-sizing: border-box;
            color: var(--dark-blue);
            margin: 0 0 40px;
        }
    }
    .blue_part {
        background-color: var(--blue);
        width: 100vw;
        .sect_03 {
            padding: 100px var(--margin);
            h2 {
                margin: 0 0 56px;
                color: white;
            }
            > .grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(2, 1fr);
                gap: 20px;
            }
        }
        .sect_04 {
            width: 100vw;
            height: calc(90vh + 200px);
            box-sizing: border-box;
            padding: 120px 20px;
            .main-content {
                width: 100%;
                height: 100%;
                border-radius: 30px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    // box-shadow: inset 0 0 10px black;
                    box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                > div {
                    color: white;
                    padding: 24px 28px;
                    margin-bottom: 44px;
                    background-color: var(--blue);
                    width: 400px;
                    box-sizing: border-box;
                    border-radius: 8px 0 0 8px;
                    position: relative;
                    h2 {
                        margin: 0 0 8px;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
        .sect_05 {
            padding: 100px var(--margin) 100px;
            width: 100vw;
            box-sizing: border-box;
            h2 {
                color: white;
                margin: 0 0 56px;
            }
            .grid {
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                // grid-template-columns: 1fr auto 1fr auto 1fr;
                --step-width: 235px;
                > span {
                    width: 48px;
                    height: 48px;
                }
                .step_block {
                    height: 100%;
                    width: var(--step-width);
                    > p {
                        margin: 0;
                    }
                }
            }
        }
        .sect_06 {
            width: 100vw;
            padding: 100px var(--margin) 100px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            > img {
                max-height: 600px;
                width: 400px;
                // aspect-ratio: 1;
                background-color: white;
                object-fit: cover;
                object-position: top;
                margin-right: 100px;
                border-radius: 8px;
            }
            .text {
                color: white;
                max-width: 600px;
                h2 {
                    margin: 0 0 8px;
                }
                h4 {
                    margin: 0 0 36px;
                }
                p {
                    font-style: italic;
                    margin: 0 0 24px;
                }
                .list {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: -20px;
                    overflow: hidden;
                    button {
                        margin: 0 20px 20px 0;
                        outline: none;
                        border: 2px solid white;
                        border-radius: 8px;
                        background: none;
                        color: white;
                        padding: 6px 12px;
                        cursor: pointer;
                        &:hover {
                            background-color: white;
                            color: var(--blue);
                        }
                    }
                }
            }
        }
    }
    .sect_07 {
        padding: 120px var(--margin) 200px;
        width: 100vw;
        box-sizing: border-box;
        background-color: white;
        h2 {
            color: var(--blue);
            margin: 0 0 56px;
        }
        .carousel {
            margin: 0 calc(-1 * var(--margin)) 120px;
            position: relative;
            .scrollable {
                display: flex;
                align-items: stretch;

                overflow: scroll;
                scroll-snap-type: x proximity;
                .quote_block {
                    width: 488px;
                    flex-shrink: 0;
                    color: var(--dark-blue);
                    &:first-child {
                        margin: 0 0 0 var(--margin);
                    }
                    &:last-child {
                        margin: 0 var(--margin) 0 0;
                    }
                    &:not(:last-child) {
                        margin-right: 24px;
                    }
                }
            }
            .control {
                width: var(--margin);
                position: absolute;
                height: 100%;
                background: linear-gradient(
                    270deg,
                    #ffffff 54.48%,
                    rgba(255, 255, 255, 0.97) 79.48%,
                    rgba(255, 255, 255, 0.86) 89.9%,
                    rgba(255, 255, 255, 0) 100%
                );
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.r {
                    right: 0;
                }
                &.l {
                    left: 0;
                    transform: rotate(180deg);
                }
                img {
                    width: 64px;
                    height: 64px;
                }
            }
        }
        .grid {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr 1fr;
            // >:nth-last-col
            position: relative;
            gap: 20px;
            img {
                width: 100%;
                aspect-ratio: 1;
                border-radius: 8px;
                object-fit: cover;
                object-position: center;
                cursor: pointer;
            }
            .overlay {
                width: 100%;
                position: absolute;
                bottom: -70px;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;
                align-items: center;
                align-content: flex-end;
                height: 200px;
                box-sizing: border-box;
                padding: 0 0 18px;
                background: linear-gradient(
                    180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(255, 255, 255, 0.51) 16.98%,
                    rgba(255, 255, 255, 0.79) 29.48%,
                    #ffffff 48.23%
                );
                .line {
                    height: 3px;
                    background-color: var(--blue);
                    border-radius: 2px;
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    main.home {
        .sect_01 p {
            padding-right: 0px;
        }
        .blue_part .sect_05 .grid {
            --step-width: 250px;
        }
    }
}

@media screen and (max-width: 1080px) {
    main.home {
        .sect_01 {
            > img {
                width: 100%;
                object-fit: cover;
                object-position: center;
                max-height: 400px;
            }
            .photo {
                width: 100%;
                max-height: 400px;
                img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        .blue_part .sect_03 {
            > .grid {
                grid-template-columns: repeat(2, 1fr);
            }
        }
        .blue_part .sect_05 .grid {
            --step-width: 250px;
            > img {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 980px) {
    main.home {
        .blue_part {
            .sect_05 .grid {
                --step-width: min(100%, 500px);
                flex-direction: column;
                .step_block {
                    margin-bottom: 40px;
                }
            }
            .sect_06 {
                > img {
                    margin-right: 40px;
                }
            }
        }
    }
}
@media screen and (max-width: 980px) {
    main.home {
        .blue_part {
            .sect_06 {
                padding: 20px var(--margin) 64px;
                flex-direction: column;
                img {
                    margin-right: 0;
                    margin-bottom: 56px;
                }
                .text {
                    max-width: unset;
                }
            }
        }
    }
}
@media screen and (max-width: 680px) {
    main.home {
        .sect_07 {
            .carousel .control {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 540px) {
    section.hero {
        &.desktop {
            display: none;
        }
        &.mobile {
            display: block;
        }
        .hero-page.first h1,
        .hero-page.second h1,
        .hero-page.third h1,
        .hero-page.fourth h1 {
            font-size: 38px;
            width: 100%;
            word-break: keep-all;
            hyphens: auto;
        }
    }
    main.home {
        .sect_07 {
            .carousel {
                margin-bottom: 40px;
                .scrollable .quote_block {
                    width: calc(100vw - 2 * var(--margin));
                    &:not(:last-child) {
                        margin-right: 12px;
                    }
                }
            }
            .grid {
                grid-template-columns: repeat(3, 1fr);
                .overlay {
                    grid-template-columns: 1fr;
                    // height: 120px;
                    .line {
                        display: none;
                    }
                    button {
                        width: 100%;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 520px) {
    main.home {
        .blue_part .sect_03 {
            > .grid {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}
@media screen and (max-width: 450px) {
    main.home {
        .desktop-only {
            display: none;
        }
        .sect_01 {
            .photo {
                height: fit-content;
            }
        }
        .blue_part {
            .sect_03 {
                padding: 80px var(--margin) 40px;
            }
            .sect_06 {
                img {
                    width: calc(100vw - 2 * var(--margin));
                }
                .text .list button {
                    text-align: left;
                    width: max-content;
                }
            }
        }
    }
}
