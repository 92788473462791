footer {
    width: 100vw;
    background-color: var(--blue);
    padding: 104px var(--margin) 16px;
    color: white;
    box-sizing: border-box;

    .main-content {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        margin-bottom: 50px;
        column-gap: 20px;
        width: 100%;

        .left {
            grid-column: 1/6;

            h1 {
                max-width: 438px;
                margin: 0 0 24px;
            }

            p {
                margin: 0 0 32px;
            }
        }

        .right {
            grid-column: 8/13;
            display: grid;
            grid-template-columns: 1fr;
            grid-auto-rows: 1fr;
            padding: 25px 0;
            row-gap: auto;

            >div {
                display: flex;
                align-items: center;

                img {
                    padding: 16px;
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                    object-position: center;
                    box-sizing: border-box;
                    margin-right: 20px;
                }

                a {
                    text-decoration: none;
                    color: white;
                    width: max-content;

                    &.one-line {
                        display: none;
                    }
                }
            }
        }
    }

    >span a {
        color: white;
        text-underline-offset: 3px;
    }
}

@media screen and (max-width: 1340px) {
    footer .main-content .left {
        grid-column: 1/7;
    }
}

@media screen and (max-width: 1120px) {
    footer {
        padding-top: 72px;

        .main-content {
            display: flex;
            flex-direction: column-reverse;
            align-items: stretch;

            .right {
                display: grid;
                grid-template-columns: 380px auto;
                gap: 20px;
                margin: 0;
                margin-bottom: 40px;

                >div {

                    // margin-right: 12px;
                    >img {
                        padding: 0px;
                        width: 40px;
                        height: 40px;
                        flex-shrink: 0;
                        // margin-right: 12px;
                    }

                    &:first-child {
                        grid-column: 2/3;
                    }

                    &:nth-child(2) {
                        grid-column: 1/2;
                        grid-row: 1/2;
                    }

                    // &:last-child {
                    //     display: none;
                    // }
                    a.one-line {
                        display: flex;
                    }

                    a.two-lines {
                        display: none;
                    }

                }
            }
        }
    }
}

@media screen and (max-width: 780px) {
    footer {
        padding-top: 64px;

        .main-content {
            .right {
                display: grid;
                grid-template-columns: 1fr;
                gap: 20px;
                margin: 0 0 40px;

                >div {
                    &:first-child {
                        grid-column: unset;
                    }

                    &:nth-child(2) {
                        grid-column: unset;
                        grid-row: unset;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 660px) {
    footer {
        padding-top: 64px;

        .main-content {
            .right {
                >div {
                    max-width: calc(100vw - 48px);

                    &:nth-child(2) {
                        grid-column: unset;
                        grid-row: unset;
                    }

                    &:nth-last-child(2) {
                        a.one-line {
                            display: none;
                        }

                        a:not(.one-line) {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}