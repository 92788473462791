.upload-popup {
    padding: 32px 40px;
    background-color: white;
    box-shadow: 0px 0px 16px rgba(196, 196, 196, 0.5);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    width: 500px;
    max-width: 100vw;
    max-height: 100vh;

    .upload-head {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 32px;
        div {
            h6 {
                margin: 0;
                color: var(--dark-blue);
            }
            span {
                color: var(--high-alpha-blue);
                font-weight: 600;
                font-style: italic;
            }
        }
        img {
            opacity: 0.4;
            cursor: pointer;
            margin-left: 16px;
            height: 20px;
            width: 20px;
            &:hover {
                opacity: 1;
            }
        }
    }
    .upload-main {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: 1fr;
        gap: 12px;
        margin-bottom: 24px;
        overflow: auto;
        padding-right: 10px;
        margin-right: -10px;
        .upload-item {
            border: 2px solid var(--blue);
            padding: 8px 14px;
            box-sizing: border-box;
            border-radius: 6px;
            width: 100%;
            display: flex;
            align-items: center;
            color: var(--dark-blue);
            cursor: pointer;
            .name {
                flex-grow: 1;
                width: 100%;
                word-break: break-all;
            }
            .size {
                flex-shrink: 0;
                margin: 0 8px;
            }
            img {
                flex-shrink: 0;
                cursor: pointer;
                padding: 4px;
                border-radius: 4px;
                &:hover {
                    background-color: var(--low-alpha-blue);
                }
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        flex-wrap: wrap;
        button {
            &:first-child {
                margin-right: 8px;
                background-color: white;
                color: var(--blue);
                border: 2px solid var(--blue);
            }
        }
    }
}
@media screen and (max-width: 388px) {
    .upload-popup {
        .buttons {
            button {
                width: 100%;
                margin-bottom: 8px;
                &:first-child {
                    margin-right: 0;
                }
            }
        }
    }
}
