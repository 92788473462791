main.admin {
    width: 100vw;
    min-height: calc(100vh - 164px);
    background-color: white;
    padding: 36px var(--margin) 100px;
    box-sizing: border-box;
    .head {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        h1 {
            display: flex;
            align-items: center;
            color: var(--dark-blue);
            margin: 0 56px 0 0;
            img {
                margin-right: 16px;
                position: relative;
                top: -2px;
            }
        }
        .search {
            position: relative;
            height: 28px;
            width: 288px;
            border-bottom: 2px solid var(--dark-blue);
            input {
                width: 100%;
                height: 100%;
                padding: 0 20px 0 0;
                margin: 0;
                border: none;
                outline: none;
                background: none;
                font-family: var(--nunito);
                font-weight: 700;
                font-size: 16px;
                line-height: 100%;
                &::placeholder,
                &::-moz-placeholder,
                &::-moz-placeholder {
                    color: var(--dark-blue);
                    opacity: 1;
                }
                position: relative;
                z-index: 1;
            }
            img {
                position: absolute;
                top: 4px;
                right: 0px;
                z-index: 0;
            }
        }
    }
    .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 20px;
        color: avr(--dark-blue);
        .add {
            > div {
                padding: 18px 24px;
                width: min-content;
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                align-items: center;
                img {
                    margin-right: 16px;
                }
                span {
                    line-height: 100%;
                    width: max-content;
                }
                &:hover {
                    background-color: #f0f0f0;
                }
            }
        }
        .item {
            padding: 18px 36px 18px 102px;
            display: grid;
            grid-template-columns: 200px 1fr 250px 80px 30px;
            gap: 40px;
            height: 64px;
            box-sizing: border-box;
            background: #ffffff;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
            border-radius: 8px;
            &:not(:hover) {
                > *:last-child {
                    display: none;
                }
            }
            > span {
                line-height: 100%;
                display: flex;
                align-items: center;
            }
            button {
                outline: none;
                border: none;
                background-color: var(--dark-blue);
                color: white;
                font-family: var(--nunito);
                font-size: 14px;
                font-weight: 700;
                border-radius: 8px;
                height: 32px;
                cursor: pointer;
            }
            img {
                cursor: pointer;
                &:hover {
                    background-color: lightgray;
                    border-radius: 2px;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {
    main.admin {
        .head .search {
            display: none;
        }
        .content {
            .add {
                display: none;
            }
            .item {
                display: flex;
                flex-wrap: wrap;
                height: unset;
                width: 100%;
                box-sizing: border-box;
                padding: 20px 20px;
                flex-direction: column;
                gap: 20px;
                * {
                    word-break: break-word;
                }
                &:hover {
                    > *:last-child {
                        display: none;
                    }
                }
            }
        }
    }
}
