.basic_card {
    position: relative;
    background-color: white;
    height: 380px;
    overflow: hidden;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
        outline: 4px solid white;
    }
    &.large{
        height: unset;
    }
    > img {
        height: 100%;
        width: 100%;
        flex-shrink: 1;
        flex-grow: 1;
        object-fit: cover;
        object-position: center;
    }
    >div {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        flex-shrink: 0;
        padding: 24px 32px;
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        background-color: rgba(255,255,255, .55);
        h4 {
            margin:0;
            text-align: center;
            color: var(--blue);
        }
    }
}