main.prestation {
    padding: 40px var(--margin) 200px;
    width: 100vw;
    box-sizing: border-box;
    color: var(--blue);
    background-color: white;
    h1 {
        margin: 0 0 64px;
    }
    h2 {
        margin: 0 0 16px;
    }
    p {
        // max-width: 600px;
        color: var(--dark-blue);
        margin: 0 0 80px;
    }
    .grid {
        display: grid;
        // grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr auto;
        &.card-4 {
            grid-template-rows: 1fr auto 1fr auto;
        }
        gap: 20px 40px;
        img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            max-height: 330px;
            object-fit: cover;
            object-position: center;
        }
        > div {
            h4 {
                margin: 0 0 8px;
            }
            p {
                margin: 0;
            }
            margin-bottom: 40px;
        }
    }
}

@media screen and (max-width: 340px) {
    main.prestation {
        h1 {
            font-size: 40px;
        }
    }   
}