main.legal {
    padding: var(--margin);
    font-family: var(--nunito);
    background-color: white;
    cursor: default;
    button.back {
        cursor: pointer;
        font-family: var(--nunito);
        background: none;
        border: none;
        outline: none;
        font-size: 24px;
        padding: 0;
        margin: 0 0 24px;
        font-weight: 700;
        svg{
            width: 20px;
            margin-right: 12px;
        }
    }
    h2 {
        font-size: 28px;
        padding: 0;
        margin: 28px 0 12px;
        font-weight: 600;
    }
    h3 {
        font-size: 18px;
        padding: 0;
        margin: 16px 0 8px;
        font-weight: 600;
    }
    a,
    a:hover,
    a:visited {
        color: black;
    }
    p{
        text-align: justify;
    }
}
