main.form-complete {
    width: 100vw;
    box-sizing: border-box;
    padding: 0 var(--margin) 0 calc(2 * var(--margin));
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 164px - 80px);
    background-color: white;
    .content {
        max-width: 490px;
        color: var(--blue);
        margin-bottom: 120px;
        img,
        h2 {
            margin: 0 0 16px;
        }
    }
}

@media screen and (max-width: 450px) {
    main.form-complete {
        justify-content: unset;
        padding: 80px var(--margin) 40px;
        height: unset;
    }
}
