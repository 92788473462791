.star-rating {
    display: flex;
    svg {
        height: 32px;
        width: 32px;
        overflow: visible;
        cursor: pointer;
        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}
