.quote_block {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 488px;
    border: 2px solid var(--blue);
    border-radius: 8px;
    box-sizing: border-box;

    word-break: keep-all;
    hyphens: auto;
    img {
        position: absolute;
        height: 32px;
        width: auto;
        &:nth-child(1) {
            top: 16px;
            left: 14px;
        }
        &:nth-child(2) {
            bottom: 16px;
            right: 14px;
            transform: rotate(180deg);
        }
    }
    .author {
        width: 100%;
        // text-align: right;
        // margin-top: 12px;
        margin-bottom: 8px;
    }
}
