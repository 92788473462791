main.contact {
    width: 100vw;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 36px 0 128px;
    .centered {
        .head {
            display: flex;
            flex-direction: column;
            max-width: 520px;
            color: var(--blue);
            h1 {
                width: max-content;
                margin: 0 0 11px;
            }
            p {
                margin: 0 0 56px;
                // color: var(--dark-blue);
            }
        }
        form {
            display: grid;
            grid-template-columns: 216px 216px 16px 216px 216px;
            grid-template-rows: auto repeat(6, 56px);
            grid-auto-rows: 56px;
            column-gap: 20px;
            row-gap: 16px;
            > h6 {
                color: var(--blue);
                grid-column: span 2;
                margin: 0;
            }
            .mid {
                grid-column: 3/4;
                grid-row: 1/-1;
                // background-color: blue;
            }
            .input {
                &.size0 {
                    grid-column: span 1;
                }
                &.size1,
                &.size2 {
                    grid-column: span 2;
                }
                &.size2 {
                    grid-row: span 2;
                }
            }
        }
    }
}

@media screen and (max-width: 1120px) {
    main.contact {
        padding: 36px var(--margin) 128px;
        .centered {
            margin-top: 40px;
            form {
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto repeat(5, 56px) auto repeat(5, 56px);
                grid-auto-rows: auto;
                .mid {
                    display: none;
                }
                h6 {
                    margin-top: 24px;
                }
                :nth-child(2) {
                    grid-row: 1/2;
                }
                :nth-child(4) {
                    grid-row: 2/3;
                }
                :nth-child(6) {
                    grid-row: 3/4;
                }
                :nth-child(7) {
                    grid-row: 3/4;
                }
                :nth-child(9) {
                    grid-row: 4/5;
                }




                :nth-child(3) {
                    grid-row: 7/8;
                }
                // :nth-child(6) {
                //     grid-row: 8/9;
                // }
                :nth-child(10) {
                    grid-row: 4/5;
                }
                :nth-child(11) {
                    grid-row: 5/6;
                }
                :nth-child(13) {
                    grid-row: 6/7;
                }
                :nth-child(15) {
                    // display: none;
                }
                :nth-child(16) {
                    margin-top: 32px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    main.contact {
        justify-content: unset;
        .centered {
            margin-top: 40px;
            .head {
                h1 {
                    width: unset;
                }
            }
            form {
                grid-template-columns: 1fr;
                grid-template-rows: auto repeat(7, 56px) auto repeat(6, 56px);
                grid-auto-rows: auto;
                h6 {
                    grid-column: unset;
                }
                .input {
                    &.size0 {
                        grid-column: span 1;
                    }
                    &.size1,
                    &.size2 {
                        grid-column: span 1;
                    }
                    &.size2 {
                        grid-row: span 3;
                    }
                }
                :nth-child(2) {
                    grid-row: 1/2;
                }
                :nth-child(5) {
                    grid-row: 10/11;
                }
                :nth-child(9) {
                    grid-row: 5/6;
                }

                
                :nth-child(3) {
                    grid-row: 9/10;
                }
                :nth-child(4) {
                    grid-row: 2/3;
                }
                // :nth-child(5) {
                //     grid-row: 3/4;
                // }
                :nth-child(7) {
                    grid-row: 4/5;
                }
                :nth-child(8) {
                    grid-row: 5/6;
                }
                :nth-child(10) {
                    grid-row: 6/7;
                }
                :nth-child(11) {
                    grid-row: 7/8;
                }
                :nth-child(13) {
                    grid-row: 8/9;
                }
                // :nth-child(6) {
                //     grid-row: 10/11;
                // }
                :nth-child(8):is(.input.size2) {
                    grid-row: 11/14 !important;
                    height: 200px;
                }
                :nth-child(12) {
                    grid-row: 14/15;
                }
                :nth-child(14) {
                    grid-row: 15/16;
                }
            }
        }
    }
}
