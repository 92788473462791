.media-popup {
    // .popup-overlay {
        background: rgba(0, 0, 0, 0.5);
    // }
    .close {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 40px;
        aspect-ratio: 1;
    }
}
