.two_blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 100px var(--margin);
    box-sizing: border-box;
    &:not(.inverse) > :first-child,
    &.inverse > :nth-child(2) {
        width: calc(50% - 96px);
        flex-shrink: 0;
    }
    &:not(.inverse) > :nth-child(2),
    .inverse > :first-child {
        width: 50%;
        flex-shrink: 0;
    }
}
@media screen and (max-width: 1440px) {
    .two_blocks {
        &:not(.inverse) > :first-child,
        &.inverse > :nth-child(2) {
            width: calc(50% - 40px);
            flex-shrink: 0;
        }
    }
}
@media screen and (max-width: 1080px) {
    .two_blocks {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        &:not(.inverse) > :first-child,
        &.inverse > :nth-child(2),
        &:not(.inverse) > :nth-child(2),
        .inverse > :first-child {
            width: 100%;
            flex-shrink: 0;
        }
        &:not(.inverse) > :first-child,
        &.inverse > :nth-child(2) {
            margin-bottom: 56px;
        }
    }
}
@media screen and (max-width: 500px) {
    .two_blocks {
    padding: 40px var(--margin) 80px;

        &:not(.inverse) > :first-child,
        &.inverse > :nth-child(2) {
            margin-bottom: 32px;
        }
    }
}
