@keyframes load {
    0% {
        background-position: 0% center;
    }
    100% {
        background-position: -300% center;
    }
}
.input:not(.no-input) {
    background: white;
    border: 2px solid var(--blue);
    border-radius: 12px;
    color: var(--low-alpha-dark-blue);
    display: flex;
    align-items: center;
    position: relative;
    user-select: none;
    height: 56px;
    &.date-input {
        span,
        input {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 14px 24px;
        }
        &.empty {
            input {
                display: none;
            }
            &:hover {
                > span {
                    display: none;
                }
                input {
                    display: flex;
                }
            }
        }
        &:not(.empty) {
            > span {
                display: none;
            }
        }
    }
    &.select-input {
        color: var(--blue);
        * {
            cursor: pointer;
        }
        > select {
            opacity: 0;
            position: absolute;
            z-index: 0;
        }
        > svg,
        > img {
            position: absolute;
            right: 24px;
            height: 28px;
            width: 28px;
            bottom: 14px;
            cursor: pointer;
        }
        .main {
            margin: -12px 0;
            padding: 12px 24px;
            width: 100%;
            box-sizing: border-box;
            &.empty {
                color: var(--low-alpha-dark-blue);
            }
        }
        .options {
            display: none;
        }
        &.open {
            .options {
                z-index: 10;
                position: absolute;
                top: calc(100% - 12px);
                left: -2px;
                right: -1.5px;
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 0px 0px 12px 12px;
                box-sizing: border-box;
                // width: 100%;
                padding-top: 12px;
                border: 2px solid var(--blue);
                border-top: none;
                overflow: hidden;
                max-height: 500px;
                overflow-y: auto;
                color: var(--blue);
                .option {
                    cursor: pointer;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 8px 24px;
                    &:hover {
                        background-color: var(--low-alpha-blue);
                    }
                }
            }
        }
    }
    &.send-input {
        cursor: pointer;
        background-color: var(--blue);
        color: white;
        justify-content: center;
        border: none;
        &.disabled {
            opacity: 0.6;
        }
        &.loading:not(.disabled) {
            background: rgb(45, 119, 238);
            background: linear-gradient(
                90deg,
                rgba(45, 119, 238, 1) 20%,
                rgb(112, 161, 239) 50%,
                rgba(45, 119, 238, 1) 80%
            );
            background-size: 300%;
            animation: load 5s infinite linear;
        }
    }
    &.button-input {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 14px 24px;
        box-sizing: border-box;
        .head {
            width: 32px;
            height: 32px;
            margin: -2px 8px -2px 0;
        }
        .content {
            width: 100%;
            flex-shrink: 1;
            flex-grow: 1;
            color: var(--blue);
        }
        .tail {
            width: 24px;
            height: 24px;
            margin: 0 0 0 8px;
        }
    }
    input,
    textarea {
        outline: none;
        border: none;
        background: none;
        height: 100%;
        width: 100%;
        padding: 14px 24px;
        margin: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        color: var(--blue);
        resize: none;
        &::placeholder,
        &::-moz-placeholder,
        &::-moz-placeholder {
            color: var(--low-alpha-dark-blue);
            opacity: 1;
        }
    }
    &.size2 {
        height: 128px;
        align-items: flex-start;
        textarea {
            align-items: flex-start;
        }
    }
}
