header {
    height: 164px;
    width: 100vw;
    background-color: white;
    display: flex;
    padding: 30px var(--margin);
    align-items: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    user-select: none;
    z-index: 100;
    position: relative;
    > a {
        height: 60px;
        img {
            height: 60px;
            width: auto;
        }
    }
    .mobile {
        display: none;
    }
    nav {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        > * {
            margin-left: 24px;
        }
        a {
            // padding: 12px 20px;
            padding: 6px 10px;
            border-radius: 8px;
            background-color: white;
            position: relative;
            cursor: pointer;
            color: var(--dark-blue);
            &:hover {
                color: var(--blue);
                // background-color: var(--low-alpha-blue);
            }
            &:active {
                // color: white;
                // background-color: var(--blue);
            }
            &:is(nav > a):last-child {
                margin-right: -20px;
            }
            &.current {
                color: var(--blue);
                &::after {
                    content: "";
                    height: 4px;
                    left: 0;
                    right: 0;
                    bottom: -8px;
                    position: absolute;
                    border-radius: 2px;
                    background-color: var(--blue);
                }
            }
        }
        .deroulant {
            > span {
                padding: 12px 20px;
                margin: 0 -10px;
                // padding: 6px 10px;

                border-radius: 8px;
                background-color: white;
                color: var(--dark-blue);
                cursor: pointer;
            }
            &:hover {
                > span {
                    background-color: var(--low-alpha-blue);
                }
            }
            &.open {
                > span {
                    color: var(--blue);
                }
                position: relative;
                .content {
                    position: absolute;
                    padding-top: 36px;
                    top: calc(100% + 12px);
                    left: -10px;
                    .list {
                        box-shadow: 0px 0px 8px rgba(45, 119, 238, 0.2);
                        overflow: hidden;
                        border-radius: 8px;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        a {
                            box-sizing: border-box;
                            width: 100%;
                            border-radius: 0;
                            padding: 12px 20px;
                        }
                    }
                }
            }
            &.close {
                .content {
                    display: none;
                }
            }
        }
    }
}

@media screen and (max-width: 980px) {
    header {
        flex-direction: column;
        align-items: flex-start;
        height: 180px;
        > a {
            margin-bottom: 20px;
        }
        nav {
            margin-left: -10px;
            .deroulant {
                margin-left: 0;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    header {
        height: 100px;
        flex-direction: row;
        padding: 20px var(--margin);
        > a {
            margin: 0;
        }
        nav.desktop {
            display: none;
        }
        img.mobile {
            display: block;
            margin: 10px 16px 0 0;
            height: 40px;
            width: 40px;
        }
        nav.mobile {
            display: flex;
            flex-direction: column;
            padding: 80px 40px 40px;
            position: fixed;
            justify-content: flex-start;
            top: 0;
            right: -350px;
            height: 100vh;
            background-color: white;
            color: var(--dark-blue);
            box-sizing: border-box;
            width: 350px;
            align-items: flex-start;
            .close {
                position: absolute;
                top: 30px;
                right: 40px;
                width: 40px;
                height: 40px;
            }
            .list {
                display: flex;
                flex-direction: column;
                margin: 0 0 24px 16px;
            }
            h6 {
                margin: 0 0 16px;
            }
            > a {
                margin: 0 0 16px;
                padding: 0;
            }
            transition-duration: .4s;
            &.open {
                right: 0;
                box-shadow: -8px 0px 12px rgba(0, 0, 0, 0.2);
                transition-duration: .4s;
            }
        }
    }
}
@media screen and (max-width: 350px) {
    header {
        nav.mobile {
            width: 100vw;
            .list {
                display: flex;
                flex-direction: column;
                margin: 0 0 16px 10px;
                width: 100%;
                a {
                    width: 100%;
                }
            }
        }
    }
}