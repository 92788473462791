.hero-page {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    .overlay {
        width: 50vw;
        height: 100%;

        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.85) 48.23%,
            rgba(255, 255, 255, 0.425) 86.77%,
            rgba(255, 255, 255, 0) 100%
        );
        // opacity: 0.75;

        padding-left: var(--margin);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}
@media screen and (max-width: 1280px) {
    .hero-page{
        .overlay{
            width: 600px;
        }
    }
}
@media screen and (max-width: 600px) {
    .hero-page{
        .overlay{
            width: 100vw;
            padding-right: 16px;
        }
    }
}