main.galerie {
    width: 100vw;
    background-color: white;
    box-sizing: border-box;
    padding: 80px var(--margin);
    h1 {
        color: var(--blue);
        margin: 0 0 56px;
    }
    > .section-deroulante.sect {
        margin: 0 0 32px;
        .head > div > h2 {
            color: var(--blue);
            margin: 0;
        }
        .body > .content .grid {
            padding: 40px 2px;
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-auto-rows: 1fr;
            gap: 20px;
            box-sizing: border-box;
            img {
                width: 100%;
                height: 100%;
                background-color: white;
                // background-color: var(--low-alpha-blue);
                object-fit: contain;
                border-radius: 8px;
                aspect-ratio: 1;
                border: 1px solid var(--low-alpha-dark-blue);
                cursor: pointer;
            }
        }
    }
}

.section-deroulante {
    background-color: white;
    .head {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 2px solid var(--high-alpha-blue);
        img {
            transition-duration: 0.4s;
            transition-property: transform;
            height: 32px;
            width: 32px;
        }
    }
    .body {
        height: 0px;
        overflow: hidden;
        transition-property: height;
        transition-duration: 0.4s;
        .content {
            height: fit-content;
            overflow: hidden;
            margin: 0;
        }
    }
}

@media screen and (max-width: 1280px) {
    main.galerie {
        > .section-deroulante.sect {
            .body > .content .grid {
                grid-template-columns: repeat(3, 1fr);
            }
        }
    }
}
@media screen and (max-width: 800px) {
    main.galerie {
        > .section-deroulante.sect {
            .body > .content .grid {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
@media screen and (max-width: 450px) {
    main.galerie {
        padding-top: 32px;
        h1 {
            font-size: 40px;
            margin-bottom: 32px;
        }
        > .section-deroulante.sect {
            .body > .content .grid {
                grid-template-columns: repeat(3, 1fr);
                gap: 2px;
                img {
                    border: none;
                    border-radius: 0px;
                }
            }
        }
    }
}
@media screen and (max-width: 350px) {
    main.galerie {
        > .section-deroulante.sect {
            .body > .content .grid {
                grid-template-columns: repeat(2, 1fr);
                gap: 2px;
            }
        }
    }
}
